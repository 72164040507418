<template>
  <div class="detail">
    <div class="container">
      <Menu/>
      <div class="main">
        <Header @getContract="getContract"/>
        <div class="welcome">
          <span>Check,</span> Card Details
        </div>
        <div class="center">
          <div class="title">
            <img src="@/assets/imgs/icon_card.png">
            <div class="text">Card Details</div>
          </div>
          <div class="content">
            <div class="item">
              <div class="label">Card Name</div>
              <div class="value">{{ cardInfo.u_name }}</div>
            </div>
            <div class="item">
              <div class="label">International Mobile Number</div>
              <div class="value">{{ cardInfo.mobile }}</div>
            </div>
            <div class="item">
              <div class="label">Login E-mail</div>
              <div class="value">{{ cardInfo.login_email }}</div>
            </div>
            <div class="item">
              <div class="label">Contact E-mail</div>
              <div class="value">{{ cardInfo.email }}</div>
            </div>
            <div v-if="cardInfo.country_name" class="item">
              <div class="label">Address</div>
              <div class="value">
                {{ cardInfo.country_name + ' ' + cardInfo.area_line_1 + ' ' + cardInfo.area_line_2 + ' ' + cardInfo.city + ' ' + cardInfo.province }}
              </div>
            </div>
            <div class="item">
              <div class="label">Card Number</div>
              <div class="value">{{ cardInfo.bank_card_code }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router'
  import { getCardDetail } from '@/request/api'
  import { ref } from 'vue'

  const route = useRoute()
  const id = route.query.id
  const cardInfo = ref({})
  function getContract(obj) {
    getCardDetail({
      address: obj.address,
      id
    }).then(res => {
      if(res.success) {
        cardInfo.value = res.data
      }
    })
  }
</script>

<style lang="less" scoped>
.detail{
  min-height: 100vh;
  background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);

  .container {
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .main {
      width: 888px;
      padding-top: 20px;

      .welcome{
        font-size: 33px;
        margin: 54px 0 27px 0;

        span{
          color: #697377;
        }
      }
      .center {
        border-radius: 10px;
        border: 2px solid transparent;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(180deg, #000000, #000000), linear-gradient(180deg, #949494, transparent 60%, transparent 100%);
        padding: 20px 24px 0 24px;
        min-height: calc(100vh - 210px);

        .title{
          display: flex;
          align-items: center;
          font-size: 17px;
          margin-bottom: 28px;

          img{
            width: 21px;
            margin-right: 17px;
          }
        }
        .content{
          padding: 0 38px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          .item{
            margin-bottom: 20px;

            .label{
              font-size: 12px;
              color: #06C8A1;
              line-height: 20px;
            }
            .value{
              width: 347px;
              font-size: 14px;
              line-height: 23px;
              padding-bottom: 2px;
              border-bottom: 0.7px solid #06C8A1;
            }
          }
        }
      }
    }
  }

}
</style>
